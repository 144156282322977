import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = 'https://valheimfoodcrafting-backend-api.onrender.com';

const IngredientSearch = () => {
    const [ingredient, setIngredient] = useState('');
    const [recipes, setRecipes] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [preventBlur, setPreventBlur] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const [tableSortConfig, setTableSortConfig] = useState({ key: '', direction: '' });
    const [searchResponse, setSetResponse] = useState("Please search for an ingredient above");


    ////////////////////////////////////////////////////////////////////////////////////////
    //                                                                                    //
    //                                  EVENT HANDLERS                                    //
    //                                                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////


    const handleSearch = async (searchIngredient = ingredient) => {
        try {
            const response = await axios.get(`${apiUrl}/api/recipes/${searchIngredient}`);
            setRecipes(response.data);
            // Passes the ingredient to the API, and then sets the Recipe array to the recipe data held in the response
            setSuggestions([]);
        } catch (error) {
            console.error('Error fetching recipes: ', error);
            setRecipes([]);
        }
    };

    const fetchSuggestions = async (query) => {
        try {
            const response = await axios.get(`${apiUrl}/api/ingredients?query=${query}`);
            setSuggestions(response.data);
            setSelectedSuggestionIndex(-1);
        } catch (error) {
            console.error('Error fetching suggestions: ', error);
            setSuggestions([]);
            setSelectedSuggestionIndex(-1);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setIngredient(value);
        if (value) {
            fetchSuggestions(value);
        } else {
            setSuggestions([]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < suggestions.length) {
                handleSearch(suggestions[selectedSuggestionIndex].name);
            } else {
                handleSearch();
            }
        } else if (event.key === 'ArrowDown') {
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            setSelectedSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        }
    };

    const handleSearchBarBlur = () => {
        if(!preventBlur) {
            const suggestionsList = document.getElementById('suggestions-list');
            if(suggestionsList) {
                suggestionsList.style.display = 'none';
            }
        }
    };

    const handleSearchBarFocus = () => {
        const suggestionsList = document.getElementById('suggestions-list');
        if(suggestionsList) {
            suggestionsList.style.removeProperty('display');
        }
    };

    const handleSuggestionMouseDown = () => {
        setPreventBlur(true);
    };

    const handleSuggestionMouseUp = (suggestion) => {
        setIngredient(suggestion);
        handleSearch(suggestion);
        setPreventBlur(false);
    };

    const handleSuggestionClick = (suggestion) => {
        setIngredient(suggestion);
        handleSearch(suggestion);
    };

    const handleTableSortConfig = (key) => {
        console.log("Sorting the table...");
        console.log("Sorting by", key);
        let direction = 'ascending';
        if (tableSortConfig.key === key && tableSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setTableSortConfig({ key, direction });
    };

    const sortedRecipes = [...recipes].sort((a, b) => {
        if (a[tableSortConfig.key] < b[tableSortConfig.key]) {
            return tableSortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[tableSortConfig.key] > b[tableSortConfig.key]) {
            return tableSortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });



    ////////////////////////////////////////////////////////////////////////////////////////
    //                                                                                    //
    //                                RETURNED CONTENTS                                   //
    //                                                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <input
                type="text"
                value={ingredient}
                onChange={handleInputChange}
                placeholder="Enter ingredient here"
                onKeyDown={handleKeyDown}
                onBlur={handleSearchBarBlur}
                onFocus={handleSearchBarFocus}
            />
            <button onClick={() => handleSearch()}>Search</button>

            {suggestions.length > 0 && (
                <ul className='suggestions-list' id="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li 
                            key={suggestion.name}
                            onMouseDown={handleSuggestionMouseDown}
                            onMouseUp={() => handleSuggestionMouseUp(suggestion.name)}
                            onClick={() => handleSuggestionClick(suggestion.name)}
                            className={index === selectedSuggestionIndex ? 'selected' : ''}
                        >
                            {suggestion.name}
                        </li>
                    ))}
                </ul>
            )}

            {recipes.length === 0 && (
                <div className='recipe-table-no-recipes-found'>
                    {searchResponse}
                </div>
            )}

            {recipes.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            <th className='sortable' onClick={() => handleTableSortConfig('name')}>Name</th>
                            <th className='sortable' onClick={() => handleTableSortConfig('colour')}>Colour</th>
                            <th className='sortable' onClick={() => handleTableSortConfig('hp_effect')}>HP Effect</th>
                            <th className='sortable' onClick={() => handleTableSortConfig('stamina_effect')}>Stamina Effect</th>
                            <th className='sortable' onClick={() => handleTableSortConfig('duration')}>Duration</th>
                            <th className='sortable' onClick={() => handleTableSortConfig('hp_tick')}>HP Tick</th>
                            <th>Ingredients</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRecipes.map((recipe) => (
                            <tr key={recipe.id}>
                                <td>{recipe.name}</td>
                                <td>{recipe.colour}</td>
                                <td>{recipe.hp_effect}</td>
                                <td>{recipe.stamina_effect}</td>
                                <td>{recipe.duration}</td>
                                <td>{recipe.hp_tick}</td>
                                <td>
                                    {recipe.ingredients.map((ingredient) => (
                                        <div key={ingredient.name}>
                                            {ingredient.quantity}x {ingredient.name}
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default IngredientSearch;
