import React from 'react';
import IngredientSearch from './components/IngredientSearch';

function App() {
    return (
            <div className='App'>
                <header>
                    <h1>Valheim Food Crafting</h1>
                </header>
                <main>
                    < IngredientSearch />
                </main>
                <footer>
                    <p>2024 Red-Tiger. All rights reserved.</p>
                </footer>
            </div>
    );
}

export default App;
